import Vue from 'vue';
import { createDecorator } from 'vue-class-component';

import { AnyFormField } from './FormField';
import { ValidationQueue, IValidationQueueOptions } from './validation/queue';
import { FormValidationState } from './validation/validationState';
import { FormFieldValidationOptions } from './types';

/**
 * Converts a component class to a Form parent instance
 */
export function Form(decoratorOptions: IValidationQueueOptions = { delay: 200 }): any {
  decoratorOptions.delay = typeof decoratorOptions.delay === 'number' ? decoratorOptions.delay : 200;

  return (target: Vue, key: string) => {
    createDecorator((options, key) => {
      if (key) {
        throw new Error(
          `Form: Invalid @Form decorator usage. Expected to be applied at class level, applied on key '${key}`,
        );
      }

      options.props = options.props || ({} as Record<string, any>);

      const validationQueue = new ValidationQueue({ ...decoratorOptions });

      /**
       * Annotate the instance as being a form state manager (can be disabled if needed)
       */
      (options.props as any).formStateManager = true;

      /**
       * Form validation options
       */
      (options.props as any).zValidationOptions = {
        type: Object,
        default: () =>
          ({
            customRules: {},
            messages: {},
          } as FormFieldValidationOptions),
      };

      options.mixins = options.mixins || [];
      options.mixins.push({
        data() {
          return {
            validityCache: null,
          };
        },
      });

      /**
       * Watch for form validation options changes
       */
      options.watch = options.watch || {};
      // TODO: Check if a forceUpdate is required
      // options.watch.zValidationOptions = (options) => {
      //   // Update custom validation rules and custom error messages
      //   this.zValidationInstance.update(this, options);
      // };

      /**
       * Allow queueing a field for validation inside the validation queue
       */
      options.methods = options.methods || {};
      options.methods.queueFieldValidation = (instance: AnyFormField, sync = false) => {
        validationQueue.queue(instance, sync);
      };

      /**
       * Form validation state getter
       */
      options.computed = options.computed || {};
      options.computed.zValidation = function getValidationState() {
        if (!this.zValidationInstance) {
          // Instantiate only once per form
          this.zValidationInstance = new FormValidationState(this);
        }

        return this.zValidationInstance;
      };
    })(target, key);
  };
}
