import { Component, BaseComponent, Prop } from '@zento-lib/components';

import { FormFieldValidationState, ValidationRules } from './types';
import { ValueType, getMessageFor } from './validation/mechanisms';
import { IValidationMessage } from './Message.d';
import style from './style.scss';

export interface IValidation {
  condition: boolean;
  text: string;
}

@Component({})
export class ValidationMessage extends BaseComponent<IValidationMessage, unknown> {
  @Prop({ type: Object })
  state?: FormFieldValidationState;

  @Prop({ type: Object })
  validationParams?: ValidationRules;

  @Prop({ type: String, required: true })
  type: ValueType;

  render() {
    return (
      <span class={style.formFieldWrapper}>
        <slot />
        {this.state && this.state.errors.length ? (
          <div class={style.errorMessage}>
            {(this.state ? (this.state.pristine ? [] : this.state.errors) : []).map((rule) => {
              const data = this.validationParams[rule];

              return this.getTranslation({
                // Get message id of current field type and rule with the eventual sub rule.
                // This is required for validators sharing the same mechanism on multiple input types.
                // Ex.: pattern
                id: getMessageFor(this.type, rule, data.subRule),
                data,
              });
            })}
          </div>
        ) : null}
      </span>
    );
  }
}
