import { ValidatorOneOf } from '../../types';

export function oneOf(value: any, _, params: ValidatorOneOf<any>) {
  const valueKeeper = params.valueKeeper;

  if (!valueKeeper) {
    throw new Error('Form: Missing valueKeeper in oneOf parameters.');
  }

  return (
    params.value &&
    params.value.length &&
    params.value.some((i) => {
      return valueKeeper in i && i[valueKeeper] === value;
    })
  );
}
