import { ValidationRuleFn, IValidationRules } from '../../types';
import { AnyFormField } from '../../FormField';

import { requiredString, requiredNumber, requiredBoolean } from './required';
import { minLengthString } from './minLength';
import { maxLengthString } from './maxLength';
import { minNumber } from './min';
import { maxNumber } from './max';
import { stepNumber } from './step';
import { oneOf } from './oneOf';
import { checkedField } from './checked';
import { pattern } from './pattern';

export type ValueType = 'string' | 'number' | 'boolean';

export const ValidationMechanisms: {
  [type in ValueType]?: {
    [validationRule in keyof IValidationRules]?: ValidationRuleFn<type, validationRule>;
  };
} = {
  string: {
    required: requiredString,
    minLength: minLengthString,
    maxLength: maxLengthString,
    oneOf,
    pattern,
  },
  number: {
    required: requiredNumber,
    min: minNumber,
    max: maxNumber,
    step: stepNumber,
    pattern,
  },
  boolean: {
    required: requiredBoolean,
    checked: checkedField,
  },
};

export const ValidationMessages: {
  [type in ValueType]?: {
    [validationRule in keyof IValidationRules]?: string | Record<string, string>;
  };
} = {
  string: {
    required: 'validation_string_required',
    minLength: 'validation_string_min-length',
    maxLength: 'validation_string_max-length',
    oneOf: 'validation_string_one-of',
    pattern: {
      email: 'validation_string_pattern_email',
      name: 'validation_string_pattern_name',
      password: 'validation_string_pattern_password',
      street: 'validation_string_pattern_street',
      zipcode: 'validation_string_pattern_zipcode',
      phone: 'validation_string_pattern_phone',
      alphanumeric: 'validation_string_pattern_alphanumeric',
      alphanumspaces: 'validation_string_pattern_alphanumspaces',
      alphanumspecialchar: 'validation_string_pattern_alphanumspecialchar',
      other: 'validation_string_pattern_other',
    },
  },
  number: {
    required: 'validation_number_required',
    min: 'validation_number_min',
    max: 'validation_number_max',
    step: 'validation_number_step',
  },
  boolean: {
    required: 'validation_boolean_required',
    checked: 'validation_checked',
  },
};

/**
 * Given the validation type and/or rule (and sub rule), returns the default invalidation message associated to it
 */
export function getMessageFor(type: ValueType, rule: keyof IValidationRules | string, subRule?: string) {
  if (!ValidationMessages[type] || !ValidationMessages[type][rule]) {
    throw new Error(`Missing invalidation message for value type ${type} on rule ${rule}`);
  }

  if (typeof subRule === 'string') {
    if (!ValidationMessages[type][rule][subRule]) {
      throw new Error(`Missing invalidation message for value type ${type} on rule ${rule}, sub rule ${subRule}`);
    }

    return ValidationMessages[type][rule][subRule];
  }

  return ValidationMessages[type][rule];
}

/**
 * Given the field instance and validation rule in question, returns the invalidation message formatting
 * data object
 */
export function getMessageDataFor(instance: AnyFormField, rule: keyof IValidationRules | string) {
  return {
    value: instance.$data.value,
    params: (instance as any).parentForm.$data[instance.name].validation.rules[rule] || {},
  };
}
