import { ValidatorPattern } from '../../types';

/**
 * Tests field for:
 * - matches first character not present in list **[^<>()[\].,;:\s@"]**
 * - matches character **@** literally (case insensitive) only once
 * - matches character **.** literally (case insensitive) in email domain address
 * - at least two characters are present after **.** character
 */
// eslint-disable-next-line max-len
const emailRegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

/**
 * Tests for letters (a-z, A-Z or other latin characters) and/or spaces in field (but no space at the start)
 */
const alphaWithSpacesRegExp = /^\S+[a-zA-Z\u00C0-\u024F ]+$/;

/**
 * Tests for only digits (0-9) in field or digits (0-9) and + sign literally (case insensitive)
 */
const digitsRegExp = /^(?:[+\d]*\d|\d)$/;

/**
 * Tests field for:
 * - at least 8 characters long, max length can be anything
 * - includes at least 1 lowercase letter
 * - includes at least 1 capital letter
 * - includes at least 1 number
 * - includes at least 1 special character **!@#$%^&*,.-**
 */
const passwordRegExp = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*,.-])[\w!@#$%^&*,.-]{8,}$/;

/**
 * Tests field for:
 * - any word character **([a-zA-Z0-9])**
 * - matches characters **.,/-\';:"** literally (case insensitive)
 * - matches character ** **(space) literally (case insensitive)
 */
// eslint-disable-next-line no-useless-escape
const streetRegExp = /^[a-zA-Z0-9]+[ \w\.\,\/\-\\\'\;\:\"]{2,}([A-Za-z]\.)?([ \w]*\#\d+)?([ \w])[ \w\.\,\/\-\\\'\;\:\"]{1,}$/;

// More complex validation - use when needed
/**
 * Tests field for:
 * - matches any word, digit or other latin characters **([a-zA-Z0-9\u00C0-\u024F])**
 * - matches characters **`,!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~** literally (case insensitive)
 * - matches character ** **(space) literally (case insensitive)
 */
// const streetRegExp = /[a-zA-Z0-9\u00C0-\u024F]+[ \w\u00C0-\u024F`,!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{2,}([A-Za-z]\.)?([ \w]*\#\d+)?([ \w\u00C0-\u024F])[ \w\u00C0-\u024F`,!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{1,}$/;

/**
 * Tests field for:
 * - matches a digit **[0-9]** or alphanumeric with spaces **[a-zA-Z0-9 ]**
 * - matches exactly **5** times or **6** times or between **6 and 8** times or **5 and 8** times or **4 and 8** times
 * - matches the character **-** literally (case sensitive)
 */
// eslint-disable-next-line max-len
const zipCodeRegExp = /(^\d{5}$)|(^\d{6}$)|(^[0-9 ]{6,8}$)|(^\d{5,8}-\d{5,8}$)|(^[0-9 ]{4,8}-[0-9 ]{3,8}$)/;

/**
 * Tests field for:
 * - matches alphanumeric **[a-zA-Z0-9]**
 */
const alphaNumericRegExp = /^[a-zA-Z0-9]*$/;

/**
 * Tests field for:
 * - matches alphanumeric with spaces **[a-zA-Z0-9 ]**
 * - matches the character **,** literally (case sensitive)
 */
const alphaNumSpacesRegExp = /^[a-zA-Z0-9 ,]*$/;

/**
 * Tests field for:
 * - matches alphanumeric with special characters and without spaces **[A-Za-z0-9_@./#&+-]**
 */
const alphaNumSpecialCharRegExp = /^[A-Za-z0-9_@./#&+-]*$/;

export function pattern(value: any, _, params: ValidatorPattern) {
  switch ((params || {}).value) {
    case 'email':
      return params ? `${value}`.match(emailRegExp) !== null : true;

    case 'name':
      return params ? `${value}`.match(alphaWithSpacesRegExp) !== null : true;

    case 'phone':
      return params ? `${value}`.match(digitsRegExp) !== null : true;

    case 'password':
      return params ? `${value}`.match(passwordRegExp) !== null : true;

    case 'street':
      return params ? `${value}`.match(streetRegExp) !== null : true;

    case 'zipcode':
      return params ? `${value}`.match(zipCodeRegExp) !== null : true;

    case 'alphanumeric':
      return params ? `${value}`.match(alphaNumericRegExp) !== null : true;

    case 'alphanumspaces':
      return params ? `${value}`.match(alphaNumSpacesRegExp) !== null : true;

    case 'alphanumspecialchar':
      return params ? `${value}`.match(alphaNumSpecialCharRegExp) !== null : true;

    default:
      return new RegExp((params || {}).value || '').test(`${value}`);
  }
}
