import { ValidatorRequired } from '../../types';

/**
 * Validate a string was received if required (params = true)
 */
export function requiredString(value: any, _, params: ValidatorRequired) {
  return params ? !!(typeof value === 'string' && value.length > 0) : true;
}

/**
 * Validate a number was received if required (params = true)
 */
export function requiredNumber(value: any, _, params: ValidatorRequired) {
  // eslint-disable-next-line no-self-compare
  return params ? typeof value === 'number' && value === value : true;
}

/**
 * Validate a boolean was received if required (params = true)
 */
export function requiredBoolean(value: any, _, params: ValidatorRequired) {
  return params ? value : true;
}
