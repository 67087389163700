import { BaseComponent, Component, Prop } from '@zento-lib/components';

import {
  ValidationRule,
  ValidationRuleExtractor,
  FormFieldState,
  ValidationRuleFn,
  IFormValidation,
  IValidationRules,
  FormFieldValidationState,
} from './types';
import type { IFormField } from './FormField.d';

export type AnyFormField = FormField<any, any, any, any>;

export type PristineOnInitial = 'poi';
export type PristineOnEmpty = 'poe';
export type NeverPristine = 'np';
export type AlwaysPristine = 'ap';

export type PristinePolicy = PristineOnInitial | PristineOnEmpty | NeverPristine | AlwaysPristine;

export interface IBaseFormField<T> {
  /**
   * Array of supported validation rules of the current form field type
   */
  validationRules: { [rule in ValidationRule]?: ValidationRuleExtractor };

  name: string;

  state: FormFieldState<T>;

  required: boolean;

  valueKeeper: string;
}

@Component({})
export class FormField<T, E, Props, Extras> extends BaseComponent<IFormField<T, E, Props, Extras> | Props, Extras> {
  /**
   * Check if the validator required exists in the context of current field instance
   */
  public static validatorRequiredExtraction(instance: AnyFormField) {
    return 'required' in instance && instance.required ? instance.required : null;
  }

  public static RequiredRuleName = 'required';

  /**
   * Parent form reference keeper
   */
  private parentForm: BaseComponent<any, IFormValidation>;

  /**
   * Un-watch function keeper
   */
  protected unwatch: Array<() => void> = [];

  /**
   * Previous uncommitted value keeper
   *
   * Starts with null in order to allow initial registration validation
   */
  public prevValue = null;

  /**
   * Form field name (mandatory)
   */
  @Prop({ required: true, type: String }) name: string;

  /**
   * Form field required status
   */
  @Prop({ type: Boolean, default: false }) required?: boolean;

  /**
   * Form field state
   */
  @Prop({ type: [Object, Array] }) state: FormFieldState<T>;

  /**
   * Determines the form validation event
   */
  @Prop({ type: String, default: 'change' }) validateOn?: E;

  /**
   * Name of the property containing the current field's value inside the state object
   */
  @Prop({ type: String, default: 'value' }) valueKeeper?: string;

  /**
   * Determines if the current field validation should be done synchronously
   */
  @Prop({ type: Boolean, default: false }) sync?: boolean;

  /**
   * Allows a formField to be used embedded inside other formFields
   *
   * By default all fields are pristine irrelevant of their initial value (PristineOnInitial)
   * Fields can be pristine when missing an initialization value, but non pristine otherwise (PristineOnEmpty)
   * When dealing with embedded form fields prefer to ignore their pristine status (NeverPristine)
   */
  @Prop({ type: String, default: 'poi' }) pristinePolicy?: PristinePolicy;

  /**
   * Determines if the current form field is embedded inside another form field
   *
   * Please consider using **pristine policy** when using embedded form fields
   */
  @Prop({ type: Boolean, default: false }) embedded?: boolean;

  /**
   * Grabs the current field's value from the state while keeping track of value keeper indirections
   */
  public get fieldValue(): T {
    return this.getFieldValue();
  }

  /**
   * Sets the current field's value into the state while keeping track of value keeper indirections
   */
  public set fieldValue(v: T) {
    this.state[this.valueKeeper] = v;
  }

  /**
   * Grab state value delayed to ensure consumers won't cache the initial undefined value
   */
  public getFieldValue(): T {
    return this.state[this.valueKeeper] as T;
  }

  // Use an internal value keeper in order to allow validation of asynchronous state
  // (in order to defer state updates)
  data() {
    return {
      value: this.getFieldValue(), // Take the initial state value
      extractedRules: {}, // Determined rules applying to the current filter with parameters keeper
    };
  }

  /**
   * Map of rule names to supported validation rule determination mechanism
   */
  protected validationRules: Partial<Record<ValidationRule, ValidationRuleExtractor>> = {
    [FormField.RequiredRuleName]: FormField.validatorRequiredExtraction,
  };

  /**
   * Map of rule names to validation mechanisms (functions)
   */
  protected validationMechanisms: Partial<Record<ValidationRule, ValidationRuleFn<any, any>>> = {};

  /**
   * Extract component validation rules
   */
  protected extractRules() {
    this.$data.extractedRules = {};
    Object.keys(this.validationRules).forEach((rule) => {
      const ruleArgs = this.validationRules[rule](this, rule);

      if (ruleArgs) {
        this.$data.extractedRules[rule] = ruleArgs;
      }
    });
  }

  /**
   * Get field validation state
   */
  public get validationState(): FormFieldValidationState {
    return this.parentForm.extended.zValidation.getFieldState(this);
  }

  protected isPristine() {
    // TODO: Test store based reactivity to lower evaluation cost
    switch (this.pristinePolicy) {
      // Initially pristine
      // If the field is not required, but empty it will be considered pristine
      case 'poi':
        return this.validationState
          ? this.validationState.pristine
          : true || (this.activeRules[0] !== FormField.RequiredRuleName && `${this.fieldValue}`.length === 0);

      // Pristine when empty
      case 'poe':
        return `${this.fieldValue}`.length === 0;

      // Never pristine
      case 'np':
        return false;

      // Always pristine
      case 'ap':
        return true;
    }
  }

  protected isValid() {
    // TODO: Test store based reactivity to lower evaluation cost
    return this.validationState ? this.validationState.errors.length === 0 : true;
  }

  /**
   * Validation rules getter
   */
  public get rules() {
    return this.$data.extractedRules;
  }

  /**
   * List of currently active rules
   */
  public get activeRules(): Array<keyof IValidationRules> {
    return (Object.keys(this.$data.extractedRules) as Array<keyof IValidationRules>).sort((a) => {
      if (a === FormField.RequiredRuleName) {
        return -1;
      }

      return 0;
    });
  }

  /**
   * Custom validation rules getter
   */
  public get customRules() {
    return this.parentForm.extended.zValidationOptions.customRules;
  }

  /**
   * List of currently active custom rules
   */
  public get activeCustomRules() {
    return Object.keys(this.customRules);
  }

  created() {
    // Ensure field registration in parent form
    this.updateFieldMeta();

    // Update parent's field data with associated rules
    this.updateFieldInParent();
  }

  beforeMount() {
    // Extract validation rules
    this.extractRules = this.extractRules.bind(this);

    this.propagateEvent = this.propagateEvent.bind(this);

    // Register watchers on all validation properties
    Object.keys(this.validationRules).forEach((rule) => {
      this.unwatch.push(this.$watch(rule, this.extractRules));
    });

    // Ensure the internal value representation remains in sync with it's outer property (value keeper on state)
    this.unwatch.push(
      this.$watch(
        `state.${this.valueKeeper}`,
        (newValue) => {
          this.$data.value = newValue;

          // Make sure that fields with programmatically modified values are validated properly
          this.queueForValidation();
        },
        { deep: true, immediate: true },
      ),
    );
  }

  beforeDestroy() {
    this.unregister();
  }

  protected unregister() {
    // Remove all custom watchers
    this.unwatch.forEach((u) => u());
    // Remove the field from the validation state
    this.parentForm.extended.zValidation.unregister(this);
  }

  /**
   * Queue for form level validation
   */
  protected propagateValue(type: E) {
    if (this.validateOn === type) {
      this.queueForValidation();
    }
  }

  /**
   * Propagate event to other interested parties
   */
  protected propagateEvent(ev: Event) {
    this.$emit(ev.type, ev);
  }

  /**
   * Queue the current field for validation at parent form level
   */
  private queueForValidation() {
    this.parentForm.extended.queueFieldValidation(this);
  }

  /**
   * Find the parent form instance
   */
  private findParent() {
    let parent = this.$parent;
    while (parent) {
      if (parent.$options.props && (parent.$options.props as any).formStateManager) {
        // Found the parent component
        this.parentForm = parent as BaseComponent<any, any>;
        break;
      }
      parent = parent.$parent;
    }
  }

  /**
   * Grabs parent references and determines active validations rules
   */
  private updateFieldMeta() {
    if (!this.parentForm) {
      // Determine the field's parent form
      this.findParent();
    }

    if (!this.parentForm) {
      throw new Error(
        'Form: Missing parent form. Make sure to decorate the form parent component with the @Form decorator.',
      );
    }

    // Determine the component's validation rules at validation creation time
    this.extractRules();
  }

  /**
   * Updates the parent form field representation
   */
  private updateFieldInParent() {
    if (!this.parentForm.extended.zValidation.has(this)) {
      // Register the new field
      if (!this.parentForm.extended.zValidation.register(this)) {
        throw new Error(`Form: Unable to register form field ${this.name} in form ${(this.parentForm as any).name}.`);
      } else {
        // Validate fields at registration time synchronously
        this.parentForm.extended.queueFieldValidation(this, true);
      }
    }
  }
}
