import type { BaseComponent } from '@zento-lib/components';

import { AnyFormField } from '../FormField';
import { FormValidationState as State, ValidationRule, FormFieldValidationState } from '../types';

export class FormValidationState {
  private instance: BaseComponent<any, any>;

  /**
   * Current validity state keeper
   */
  private state: State = {};

  /**
   * Last validity status keeper
   */
  private lastValid = false;

  public constructor(instance: BaseComponent<any, any>) {
    this.instance = instance;
  }

  /**
   * Determine if the named field is valid
   * If an index is specified the FormField at given index will be evaluated out of the list of FormFields sharing
   * the same name.
   * If an index is not provided the state of the field (or fields collection) will be returned.
   */
  public isValid(fieldName: string, index: number | null = null): ValidityState {
    if (this.state[fieldName]) {
      if (index !== null) {
        if (this.state[fieldName].instances[index]) {
          return (this.state[fieldName].instances[index].state.valid as any) as ValidityState;
        } else {
          throw new Error(
            `Form: Field ${fieldName} index out of bounds. Only ${this.state[fieldName].instances.length} instances available.`,
          );
        }
      } else {
        // Return the validity status of the entire collection
        return (this.state[fieldName].valid as any) as ValidityState;
      }
    }

    return null;
  }

  /**
   * Gets the named field's validation errors
   * If an index is specified the FormField at given index will be evaluated out of the list of FormFields sharing
   * the same name.
   * If an index is not provided the state of the field (or fields collection) will be returned.
   */
  public getErrors(fieldName: string, index: number | null): Array<ValidationRule | string> {
    if (this.state[fieldName]) {
      if (index !== null) {
        if (this.state[fieldName].instances[index]) {
          return this.state[fieldName].instances[index].state.errors;
        } else {
          throw new Error(
            `Form: Field ${fieldName} index out of bounds. Only ${this.state[fieldName].instances.length} instances available.`,
          );
        }
      } else {
        // Return the validity status of the entire collection
        return this.state[fieldName].errors;
      }
    }

    return [];
  }

  public get fieldNames() {
    return Object.keys(this.state);
  }

  /**
   * Get form level errors
   * List of all form field errors
   */
  public get errors() {
    return this.instance.$data.validityCache ? this.instance.$data.validityCache.errors : [];
  }

  /**
   * Form level validity flag
   * Only valid when all form fields are valid
   */
  public get valid() {
    return (
      this.errors.length === 0 && // There are no errors at form level
      this.instance.$data && // The form has fields
      !this.fieldNames.some((fieldName) => !this.state[fieldName].valid)
    ); // There is no invalid field
  }

  /**
   * Form validity cache invalidation mechanism
   *
   * Will regenerate the validity cache directly
   */
  public invalidateCache() {
    this.computeValidityCache();

    if (this.lastValid !== this.valid) {
      this.lastValid = this.valid;

      // const fieldInstance = this.state[this.fieldNames[0]].instances[0].instance;
      // fieldInstance.$nextTick(() => (fieldInstance as any).parentForm.$forceUpdate());
    }
  }

  /**
   * Checks if the provided field was registered
   */
  public has(field: AnyFormField, throwOnDuplicates = true): boolean {
    if (!field.name) {
      const error = new Error(`Form: Form field in form ${(this.instance as any).name} missing name.`);

      console.error(error, field);
      throw error;
    }

    const { name, inCollection } = this.extractName(field.name);

    // Check instance's uniqueness
    if (this.state[name]) {
      if (!field.embedded && !inCollection && throwOnDuplicates) {
        throw new Error(
          `Form: Duplicate form field name ${(this.instance as any).name}[${
            this.instance.locator
          }]. Consider using unique names or collections of form 'fieldName[]'`,
        );
      }

      if (this.state[name].instances.some((i) => i.instance === field)) {
        // An instance was already registered
        return true;
      }
    }

    return false;
  }

  /**
   * Field validation state getter
   */
  public getFieldState(field: AnyFormField): FormFieldValidationState | null {
    const { name } = this.extractName(field.name);

    if (this.state[name]) {
      for (const i of this.state[name].instances) {
        if (i.instance === field) {
          return i.state;
        }
      }
    }

    return null;
  }

  /**
   * Register a new field into the validation state manager
   */
  public register(field: AnyFormField): boolean {
    const exists = this.has(field);
    const { name } = this.extractName(field.name);

    if (!exists) {
      // Create the initial empty field state
      this.state[name] = this.state[name] || {
        instances: [],
        errors: [],
        valid: null, // Start with a null validity (but don't consider it pristine)
      };

      // Add the new field to the list of instances as pristine
      this.state[name].instances.push({
        instance: field,
        state: {
          errors: [],
          valid: null,
          pristine: true, // Mark as pristine initially (pristine evaluation will consider this value in some strategies)
        },
      });

      return true;
    }

    return false;
  }

  /**
   * Unregister a field from the validation state manager
   */
  public unregister(field: AnyFormField): boolean {
    if (this.has(field, false)) {
      const { name } = this.extractName(field.name);

      this.state[name].instances = this.state[name].instances.filter((i) => i.instance !== field);

      if (this.state[name].instances.length === 0) {
        // Remove the entire field validation state when the last field in the collection was removed
        // This takes care of isValid at collection level
        delete this.state[name];
      }

      return true;
    }

    return false;
  }

  /**
   * Extract the canonical name and it's multi item status
   */
  private extractName(name: string): { name: string; inCollection: boolean } {
    const inCollection = /\[\]/g.test(name);

    return {
      name: inCollection ? name.slice(0, name.indexOf('[')) : name,
      inCollection,
    };
  }

  /**
   * Compute forms validity cache
   */
  private computeValidityCache() {
    const validityCache = {
      errors: [],
    };

    this.fieldNames.forEach((fieldName) => {
      const field = this.state[fieldName];
      const fieldErrors = [];

      field.instances.forEach((instance) => {
        const state = instance.state;

        if (state.errors && state.errors.length) {
          fieldErrors.push(...state.errors);
        }
      });

      validityCache.errors.push(...fieldErrors);
      field.valid = fieldErrors.length === 0;
      field.errors = fieldErrors; // Aggregate errors at collection level
    });

    this.instance.$data.validityCache = validityCache;
  }
}
