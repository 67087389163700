import type { BaseComponent } from '@zento-lib/components';

import { AnyFormField, FormField } from '../FormField';
import type { IFormValidation, FormFieldValidationState } from '../types';

export interface IValidationQueueOptions {
  /**
   * Determines the delay to be enforced before each validation
   */
  delay?: number;

  /**
   * Determines if the value should be updated even when invalid (defaults to false)
   */
  updateOnInvalid?: boolean;

  /**
   * Determines if validation should continue after the first invalid rule
   */
  continueOnInvalid?: boolean;

  /**
   * Determines when a field will be evaluated
   * - change: a field will be considered for validation when it's value first changes
   *  (determined by it's initial value being different from it's current value)
   * - touch: a field will be considered for validation from the first event triggered on it
   */
  evaluateOn?: 'change' | 'touch'; // Defaults to change
}

export class ValidationQueue {
  private options: IValidationQueueOptions;

  private nextCycle: number;

  private requestedAnimationFrame: null | number;

  private validationQueue: AnyFormField[] = [];

  public constructor(options: IValidationQueueOptions) {
    this.options = options;

    this.revalidate = this.revalidate.bind(this);
  }

  /**
   * Queue a field for validation
   */
  public queue(field: AnyFormField, sync = false) {
    // this.validationQueue = this.validationQueue.filter((f) => f !== field);
    this.validationQueue.push(field);

    // Deregister eventually existing evaluation requests
    if (typeof cancelAnimationFrame === 'function' && this.requestedAnimationFrame !== null) {
      cancelAnimationFrame(this.requestedAnimationFrame);
      this.requestedAnimationFrame = null;
    }

    if (!field.sync && !sync) {
      // Register a revalidation
      this.nextCycle = Date.now() + (this.options.delay || 0);
      this.requestedAnimationFrame = requestAnimationFrame(this.revalidate);
    } else {
      // Validate directly on sync fields
      this.doValidate();
    }
  }

  private revalidate() {
    const now = Date.now();

    if (now >= this.nextCycle) {
      // Re-validate when reaching the debounce deadline
      this.doValidate();

      this.nextCycle = Infinity;
      this.requestedAnimationFrame = null;
    } else {
      // Reschedule
      requestAnimationFrame(this.revalidate);
    }
  }

  /**
   * Perform the validation
   */
  private doValidate() {
    const fields = Array.from(new Set(this.validationQueue)).filter((f) => {
      if (
        (f as any)._isBeingDestroyed || // The component will be destroyed
        (f as any)._isDestroyed // Was destroyed
      ) {
        return false;
      }

      if (
        (f as any)._isMounted &&
        (!f.$el || // Has no element
          !('tagName' in f.$el)) // The element is a text node
      ) {
        return false;
      }

      return true;
    });

    // Revalidate each field in order
    fields.forEach((f) => {
      const parentForm = (f as any).parentForm as BaseComponent<any, IFormValidation>;
      const field: FormFieldValidationState = parentForm.extended.zValidation.getFieldState(f);
      const fieldValue = f.getFieldValue();
      const validationMechanisms = (f as any).validationMechanisms;
      let valid = true;

      // Revalidate fields each time if evaluated on touch (touch = non pristine field)
      // eslint-disable-next-line max-len
      if (
        this.options.evaluateOn !== 'touch' &&
        fieldValue === f.$data.value &&
        f.$data.value === (f as any).prevValue
      ) {
        // Do not reevaluate fields validity state when values end up being the same as last time
        return false;
      }

      if (!field) {
        // Missing data
        throw new Error(
          `Form: Missing parent form data configuration for field ${f.name}.` +
            'Please consider providing the form field state from parent @Form level',
        );
      }

      // Reset invalidation messages before reevaluation
      field.errors = [];

      // Validate
      let required = false;
      let passesRequired = false;
      let activeRules = f.activeRules;
      if (activeRules[0] === FormField.RequiredRuleName) {
        required = f.rules[f.activeRules[0]] as boolean;
        activeRules = activeRules.slice(1);
      }

      if (!required) {
        // Non required fields with or without a value should pass required
        passesRequired = true;
      } else {
        // Evaluate required field
        passesRequired = validationMechanisms[FormField.RequiredRuleName](f.$data.value, fieldValue, required, field);
      }

      const method: 'forEach' | 'some' = this.options.continueOnInvalid ? 'forEach' : 'some';

      if (this.fieldValueChanged((f as any).prevValue, f.$data.value)) {
        field.pristine = false;
      }

      if (passesRequired) {
        // Evaluate any other validation rules if existing
        f.activeRules[method]((ruleName) => {
          const ruleParams = f.rules[ruleName];

          // Validate the field by it's defined mechanisms
          if (!validationMechanisms[ruleName]) {
            throw new Error(`No registered validation mechanism exists for validation of type ${ruleName}.`);
          } else if (!field.pristine && !validationMechanisms[ruleName](f.$data.value, fieldValue, ruleParams, field)) {
            // Found an invalid rule, stop here for most cases
            valid = false;
            field.errors.push(ruleName);

            return true;
          }
        });
      } else {
        valid = false;
        field.errors.push(FormField.RequiredRuleName);
      }

      // Validate the field by it's custom validation rules
      if (valid || this.options.continueOnInvalid) {
        f.activeCustomRules[method]((ruleName) => {
          const validationFn = f.customRules[ruleName];

          if (!validationFn || typeof validationFn !== 'function') {
            throw new Error(
              `Form: No registered validation mechanism exists for validation of type ${ruleName}. Expected function, received ${typeof validationFn}`,
            );
          } else if (!field.pristine && !validationFn(f.$data.value, fieldValue, field)) {
            // Found an invalid rule, stop here for most cases
            valid = false;
            field.errors.push(ruleName);

            return true;
          }
        });
      }

      // Update the field's validation status
      field.valid = valid;

      if (valid || this.options.updateOnInvalid) {
        // Update the parent form value keeper for the current field
        f.fieldValue = f.$data.value;

        if (process.env.NODE_ENV === 'development') {
          console.info('Field value has been updated', valid, f.$data.value);
        }
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.error('Field value was not updated', valid, f.$data.value, field.errors);
        }
      }

      // Invalidate form validity cache
      parentForm.extended.zValidation.invalidateCache();

      // Keep track of current value as previous value for further evaluations
      (f as any).prevValue = f.$data.value;

      if (!f.embedded) {
        // Force a field update a the validity evaluation is outside Vue's component lifecycle
        f.$forceUpdate();
      }
    });

    // Clean up the validation queue as the validation mechanism is sync and evaluates all fields
    this.validationQueue = [];
  }

  private fieldValueChanged(a: any, b: any) {
    // No previous value (in order to keep the field pristine on first value attribution)
    if (a === null) {
      return false;
    } else if (typeof a === 'undefined') {
      // Consider changing value from undefined to null or empty string to be a non change
      if (a === b || b === null || b === '') {
        return false;
      }
    } else if (typeof a === 'string' && a === '') {
      // Consider changing an empty string to null or undefined to be a non change
      if (a === b || b === null || b === undefined) {
        return false;
      }
    }

    return a !== b;
  }
}
